import React from 'react';

function Footer() {
    return (
        <footer className="footer mt-auto py-3">
            <div className="container border-top pt-2">
                <p className="col-md-12 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">&copy; {(new Date().getFullYear())} Behind Your Business</p>
            </div>
        </footer>
    );
}

export default Footer;