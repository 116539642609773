import { useEffect, useState, useMemo, useCallback } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import nlBE from 'date-fns/locale/nl-BE';

registerLocale('nlBE', nlBE);

function GuestsContent() {
    const { instance } = useMsal();
    const [guests, setGuests] = useState<GuestModel[]>();
    const [date, setDate] = useState(new Date());
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);

    const init = useCallback(async () => {
        try {
            let guests = await service.getGuests(date);
            setGuests(guests);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }, [service, date]);


    useEffect(() => {
        init();
    }, [date, init]);

    async function updateGuest(id: number) {
        try {
            await service.updateGuest(id);
            init();
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }

    const prevDay = () => {
        const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - 1
        );
        setDate(newDate);
    }

    const nextDay = () => {
        const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1
        );
        setDate(newDate);
    }

    function isSignOutDate(guest: any) {
        if (guest?.signOutDate === undefined)
            return "/";
        else
            return new Date(guest.signOutDate).toLocaleString("nl-BE");
    }

    return (
        <>
            <div className="row justify-content-md-center">
                <div className="col-md-auto">
                    <i className="bi bi-arrow-left-circle h2 justify-content-md-start text-primary" onClick={prevDay}></i>
                </div>
                <div className="col-md-auto">
                    <DatePicker selected={date} onChange={(date: Date) => setDate(date)} className="form-control text-center" locale="nlBE" dateFormat="dd/MM/yyyy" />
                </div>
                <div className="col-md-auto">
                    <i className="bi bi-arrow-right-circle h2 justify-content-md-start text-primary" onClick={nextDay}></i>
                </div>
            </div>
            <div className="row">
                <div className="col table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Voornaam</th>
                                <th>Achternaam</th>
                                <th>Bedrijf</th>
                                <th>GSM</th>
                                <th>Bezoek</th>
                                <th>Aangemeld</th>
                                <th>Afgemeld</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guests?.map(guest => (
                                <tr key={guest.id}>
                                    <td>{guest.firstName}</td>
                                    <td>{guest.lastName}</td>
                                    <td>{(guest.city === undefined || guest.city.length < 1) ? guest.companyName : guest.city}</td>
                                    <td>{guest.phone}</td>
                                    <td>{guest.personVisited.length < 1 ? '/' : guest.personVisited}</td>
                                    <td>
                                        {new Date(guest.date).toLocaleString("nl-BE")}
                                    </td>
                                    <td>
                                        {guest.isPresent ? <button className="btn btn-primary text-light btn-sm" onClick={() => updateGuest(guest.id)}>Afmelden</button> : isSignOutDate(guest)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function ConfigurationPage() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestsContent />
        </MsalAuthenticationTemplate>
    )
};