import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { Controller, useForm } from 'react-hook-form';
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getError } from "../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import VisitReasonModel from "../Models/VisitReasonModel";

function GuestContent() {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const { register, handleSubmit, formState: { errors }, control } = useForm<GuestModel>({ mode: "onChange" });
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();
    const [visitReasons, setVisitReasons] = useState<VisitReasonModel[]>();

    const onSubmit = handleSubmit(data => {
        async function save(data: GuestModel) {
            try {
                await service.addGuest(data);
                try {
                    let config = await service.getConfiguration();

                    if (config.notifyEmail !== "" && config.notifyEmail !== undefined) {
                        let mail = {
                            EmailSubject: "Nieuwe bezoeker aangemeld - " + data.firstName + " " + data.lastName + " voor " + data.personVisited,
                            ContactEmail: config.notifyEmail,
                            Visitor: data.firstName + " " + data.lastName,
                            Contact: data.personVisited
                        };

                        await service.sendMail(mail);
                    }
                }
                catch (error) {
                    appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Warning });
                }

                toast.success("Aanmelden gelukt");
            }
            catch (error) {
                toast.error("Fout bij aanmelden.");
                appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
            }
        }

        save(data);
        history.push('/choice');
    });

    const init = useCallback(async () => {
        try {
            let reasons = await service.getVisitReasons();
            setVisitReasons(reasons);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }, [service, appInsights]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            init();

        return () => { isMounted = false }
    }, [init]);

    const countries = {
        "BE": "België",
        "DE": "Duistland",
        "FR": "Frankrijk",
        "LU": "Luxemburg",
        "NL": "Nederland",
        "ZZ": "Internationaal"
    };

    return (
        <form onSubmit={onSubmit} className="mx-3" autoComplete="off">
            <div className="row">
                <div className="mb-2 col-sm-6">
                    <label className="form-label">Voornaam <span className="text-danger">*</span></label>
                    <input autoFocus {...register("firstName", { required: true })} className={"form-control custom-form-control " + (errors.firstName && "is-invalid")} placeholder="Voornaam" />
                    {errors.firstName && <div className="form-text text-danger">Voornaam is verplicht</div>}
                </div>
                <div className="mb-2 col-sm-6">
                    <label className="form-label">Achternaam <span className="text-danger">*</span></label>
                    <input {...register("lastName", { required: true })} className={"form-control custom-form-control " + (errors.lastName && "is-invalid")} placeholder="Achternaam" />
                    {errors.lastName && <div className="form-text text-danger">Achternaam is verplicht</div>}
                </div>
            </div>
            <div className="mb-2">
                <label className="form-label">Woonplaats</label>
                <input {...register("city")} className="form-control custom-form-control " placeholder="Woonplaats" />
            </div>
            <div className="mb-2">
                <label className="form-label">GSM</label>
                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: false,
                        validate: (value) => {
                            let valid = false;
                            if (value === undefined)
                                valid = true;
                            else if (isValidPhoneNumber(value))
                                valid = true;
                            else
                                valid = false;
                            return valid;
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <PhoneInput
                            placeholder="GSM"
                            className={"form-control custom-form-control " + (errors.phone && "is-invalid")}
                            countrySelectProps={{ unicodeFlags: true }}
                            countries={["BE", "DE", "FR", "LU", "NL"]}
                            value={value}
                            international={false}
                            onChange={onChange}
                            defaultCountry="BE"
                            labels={countries}
                            id="phone"
                        />
                    )}
                />
                {errors.phone && <div className="form-text text-danger">GSM is niet correct</div>}
            </div>
            <div className="mb-2">
                <label className="form-label">Reden van bezoek/afspraak met? <span className="text-danger">*</span></label>
                <select style={{appearance: "auto"}} {...register("personVisited", { required: true })} className={"form-control custom-form-control " + (errors.personVisited && "is-invalid")}>
                    <option key="0" value=""></option>
                    {visitReasons?.map((option) => (
                        <option key={option.id} value={option.reason}>{option.reason}</option>
                    ))}
                </select>
                {errors.personVisited && <div className="form-text text-danger">Reden is verplicht</div>}
            </div>
            <button type="submit" className="btn btn-primary w-100 mt-2 text-light btn-lg p-3 custom-primary-background custom-primary-border-color" ><b>Aanmelden</b></button>
            <div className="mt-2 fw-light fst-italic">
                <small>De persoonsgegevens in dit formulier worden enkel verwerkt voor de beveiliging van het gebouw (vb bij brand). Voor meer info over ons privacy beleid verwijzen we naar onze website.</small>
            </div>
        </form>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function Guest() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestContent />
        </MsalAuthenticationTemplate>
    )
};