import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MsalAuthenticationTemplate, MsalAuthenticationResult, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from '../Services/GuestPortalApiService';
import { Link } from 'react-router-dom';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getError } from '../appInsights';

function ChoiceContent() {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const [customerWelcomeText, setCustomerWelcomeText] = useState<string>();
    const [customerLogo, setCustomerLogo] = useState<string>();
    const [dataLoaded, setDataLoaded] = useState(false);

    const init = useCallback(async () => {
        try {
            let config = await service.getConfiguration();
            setCustomerWelcomeText(config.welcomeText);
            if (config.currentLogo !== "") {
                setCustomerLogo(process.env.REACT_APP_BLOB_URL + "/" + config.currentLogo);
            }
            else {
                setCustomerLogo("");
            }
        }
        catch (error) {
            setCustomerWelcomeText("Welkom");
            setCustomerLogo("");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
        setDataLoaded(true);
    }, [service, appInsights]);

    const alive = useCallback(async () => {
        await service.alive();
    }, [service]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        const interval = setInterval(async () => {
            alive();
        }, 1000 * 60 * 5);
        return () => clearInterval(interval);
    }, [alive]);

    if (!dataLoaded) {
        return <div />;
    }

    return (
        <>
            <div>
                <div className="row m-3 w-100">
                    <div className="col-sm-12">
                        <h1 className="text-secondary text-center fs-1 fw-bold custom-secondary-color">{customerWelcomeText}</h1>
                    </div>
                </div>
                <div className="row m-3 w-100">
                    <div className="col-sm-12 customer-logo">
                        {customerLogo && <img src={customerLogo} onError={(event) => (event.target as HTMLTextAreaElement).style.display = 'none'} className="p-4" alt="Company Logo"></img>}
                    </div>
                </div>
                <div className="row m-3 w-100">
                    <div className="col-sm-6">
                        <Link to="/guest" className="btn btn-primary text-light w-100 btn-lg p-3 fs-1 custom-primary-background custom-primary-border-color">Aanmelden</Link>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/logoutGuest" className="btn btn-primary text-light w-100 btn-lg p-3 fs-1 custom-primary-background custom-primary-border-color">Afmelden</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function Choice() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <ChoiceContent />
        </MsalAuthenticationTemplate>
    )
};