import { useEffect, useState, useMemo, useCallback } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getError } from "../appInsights";

function GuestsLogoutContent() {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const [guests, setGuests] = useState<GuestModel[]>();
    const [filteredGuests, setFilteredGuests] = useState<GuestModel[]>();
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();

    const init = useCallback(async () => {
        try {
            const today = new Date();
            let guests = await service.getGuests(today);
            setGuests(guests);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }, [service, appInsights]);


    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            init();

        return () => { isMounted = false }
    }, [init]);

    async function updateGuest(id: number) {
        try {
            await service.updateGuest(id);
            toast.success("Afmelden gelukt");
            init();
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }

    function handleChange(event: any) {
        let filterQuery = event.target.value.toLowerCase();
        filter(filterQuery);
    }

    function filter(searchQuery: string) {
        if (searchQuery.length >= 3) {
            if (guests) {
                const filter = guests.filter(guest => guest.firstName.toLowerCase().includes(searchQuery) && guest.isPresent);
                setFilteredGuests(filter);
            }
        }
        else {
            setFilteredGuests([]);
        }
    }

    function handleOnClick(guest: any) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div id="overlay">
                        <div id="modal">
                            <h3 className="pb-3">Wilt u {guest.firstName + " " + guest.lastName} afmelden?</h3>
                            <button className="btn btn-primary text-light btn-lg p-3 px-5 mx-3 fs-1 custom-primary-background custom-primary-border-color"
                                onClick={() => {
                                    onClose();
                                    updateGuest(guest.id);
                                    history.push('/choice')
                                }}
                            >
                                Afmelden
                            </button>
                            <button className="btn btn-primary text-light btn-lg p-3 px-5 mx-3 fs-1 custom-primary-background custom-primary-border-color" onClick={onClose}>Annuleren</button>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <>
            <div className="row justify-content-md-center mb-2">
                <div className="col-md-9">
                    <input className="form-control custom-form-control fs-3" autoFocus onChange={handleChange} placeholder="Zoek voornaam" />

                    <table className="table table-striped">
                        <tbody>
                            {filteredGuests?.map(guest => (
                                <tr className="fs-3" key={guest.id} onClick={() => handleOnClick(guest)}>
                                    <td>{guest.firstName} {guest.lastName}</td>
                                    <td><i className="bi bi-door-closed"></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function LogoutGuest() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestsLogoutContent />
        </MsalAuthenticationTemplate>
    )
};