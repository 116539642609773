import { useCallback, useEffect, useMemo, useState } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import ConfigurationModel from "../Models/ConfigurationModel";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

function ConfigurationContent() {
    const { instance } = useMsal();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<ConfigurationModel>({mode: "onChange"});
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const [fileSelected, setFileSelected] = useState();
    const [customerLogo, setCustomerLogo] = useState<string>();
    const [customerId, setCustomerId] = useState<number>();
    const [customerOldLogoName, setCustomerOldLogoName] = useState<string>("");

    const onSubmit = handleSubmit(data => {
        async function save(data: ConfigurationModel) {
            try {
                if(typeof(customerOldLogoName) !== 'undefined' && customerOldLogoName !== null)
                    data.currentLogo = customerOldLogoName
                if(typeof(fileSelected) !== 'undefined' && fileSelected !== null)
                {
                    if(customerId === undefined)
                        data.currentLogo = Date.now().toString();
                    else
                        data.currentLogo = customerId + Date.now().toString();
                }
                await service.updateConfiguration(data);
                if(typeof(fileSelected) !== 'undefined' && fileSelected !== null)
                {
                    if(customerOldLogoName !== "")
                        await service.deleteLogo(customerOldLogoName);
                    await service.addLogo(fileSelected);
                    setCustomerOldLogoName(data.currentLogo);
                }
                toast.success("Bewaard.");
            }
            catch (error) {
                toast.error("Fout bij bewaren.");
            }
        }

        save(data);
    });

    const init = useCallback(async () => {
        try {
            let primaryColor = "#d3950b"
            let secondaryColor = "#000000"

            let config = await service.getConfiguration();

            if(config.color !== "" && config.color !== undefined)
            {
                primaryColor = config.color;
            }
            else
            {
                if(process.env.REACT_APP_DEFAULT_PRIMARY_COLOR)
                    primaryColor =  process.env.REACT_APP_DEFAULT_PRIMARY_COLOR;
            }

            config.color = primaryColor;

            if(config.secondaryColor !== "" && config.secondaryColor !== undefined)
            {
                secondaryColor = config.secondaryColor;
            }
            else
            {
                if(process.env.REACT_APP_DEFAULT_SECONDARY_COLOR)
                    secondaryColor = process.env.REACT_APP_DEFAULT_SECONDARY_COLOR;
            }

            config.secondaryColor = secondaryColor;

            setCustomerId(config.id);
            reset({
                name: config.name,
                color: config.color,
                welcomeText: config.welcomeText,
                secondaryColor: config.secondaryColor,
                notifyEmail: config.notifyEmail,
                overviewDays: config.overviewDays
            });

            setCustomerOldLogoName(config.currentLogo);
            setCustomerLogo(process.env.REACT_APP_BLOB_URL + "/" + config.currentLogo);
        }
        catch (error) {
            reset({
                name: "",
                color: "#d3950b",
                welcomeText: "Welkom",
                secondaryColor: "#000000",
                notifyEmail: "",
                overviewDays: 7
            });
            setCustomerLogo("");
        }
    }, [service, reset]);

    useEffect(() => {
        init();
    }, [init]);

    const saveFileSelected = (e:any) => {
        setFileSelected(e.target.files[0]);
        setCustomerLogo(URL.createObjectURL(e.target.files[0]));
        const preview = document.querySelector('#CompanyLogo') as HTMLElement;
        preview.style.display = 'flex';
    };

    return (
        <form onSubmit={onSubmit} className="mx-3">
            <div className="mb-3">
                <h3>Algemene instellingen</h3>
            </div>
            <div className="mb-3">
                <label className="form-label">Bedrijfsnaam</label>
                <input {...register("name", { required: true, maxLength: 35 })} className={"form-control " + (errors.name && "is-invalid")} placeholder="Bedrijfsnaam" maxLength={35}/>
                {errors.name && errors.name.type === "required" && <div className="form-text text-danger">Bedrijfsnaam is verplicht</div>}
                {errors.name && errors.name.type === "maxLength" && <div className="form-text text-danger">Bedrijfsnaam is te lang</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Notificatie e-mail</label>
                <input {...register("notifyEmail", 
                {
                    required: false, 
                    pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                })} className={"form-control " + (errors.notifyEmail && "is-invalid")} placeholder="Email" />
                {errors.notifyEmail && <div className="form-text text-danger">Email is niet correct</div>}
            </div>
            <div className="mb-3">
                <h3>App instellingen</h3>
            </div>
            <div className="d-flex">
                <div className="mb-3 me-3">
                    <label className="form-label">Primaire themakleur</label>
                    <input type="color" {...register("color")} className="w-color-picker form-control" placeholder="Themakleur" />
                </div>
                <div className="mb-3 me-3">
                    <label className="form-label">Secundaire themakleur</label>
                    <input type="color" {...register("secondaryColor")} className="w-color-picker form-control" placeholder="Themakleur" />
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label">Verwelkomingstekst</label>
                <input {...register("welcomeText", { maxLength: 35 })} className={"form-control " + (errors.welcomeText && "is-invalid")} placeholder="Verwelkomingstekst" maxLength={35}/>
                {errors.welcomeText && errors.welcomeText.type === "maxLength" && <div className="form-text text-danger">Welkomtekst is te lang</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Huidig Logo</label>
                <div className="form-control">
                    <div className="customer-logo-thumb">
                        <input id="LogoFile" type="file" accept=".jpg, .png" onChange={saveFileSelected} className="custom-file-input" />
                    </div>
                    <div className="customer-logo-thumb">
                        <img id="CompanyLogo" src={customerLogo} onError={(event) => (event.target as HTMLTextAreaElement).style.display = 'none'} className="p-4" alt="Company Logo"></img>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <h3>Dashboard instellingen</h3>
            </div>
            <div className="mb-3">
                <label className="form-label">Aantal Dagen</label>
                <input type="number" {...register("overviewDays")} className={"form-control"} placeholder="0"/>
            </div>            
            <button type="submit" className="btn btn-primary text-light" >Bewaren</button>
        </form>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function ConfigurationPage() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <ConfigurationContent />
        </MsalAuthenticationTemplate>
    )
};