import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from "./Pages/Home";
import Choice from "./Pages/Choice";
import LogoutGuest from "./Pages/LogoutGuest";
import Guest from "./Pages/Guest";
import GuestsPage from "./Pages/Guests"
import VisitReasonsPage from "./Pages/VisitReasons"
import VisitReasonPage from "./Pages/VisitReason"
import ConfigurationPage from "./Pages/Configuration";
import { ToastContainer } from 'react-toastify';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "./authConfig";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error}</p>;
}

function App() {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                errorComponent={ErrorComponent}
            >
                <Router>
                    <Header />
                    <div className="container">
                        <Switch>
                            <Route path="/choice">
                                <Choice />
                            </Route>
                            <Route path="/logoutGuest">
                                <LogoutGuest />
                            </Route>
                            <Route path="/guest">
                                <Guest />
                            </Route>
                            <Route path="/config">
                                <ConfigurationPage />
                            </Route>
                            <Route path="/guests">
                                <GuestsPage />
                            </Route>
                            <Route path="/visitreasons">
                                <VisitReasonsPage />
                            </Route>
                            <Route path="/visitreason">
                                <VisitReasonPage />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                        <ToastContainer />
                    </div>
                    <Footer />
                </Router>
            </MsalAuthenticationTemplate>
        </AppInsightsContext.Provider>
    );
}

export default App;
