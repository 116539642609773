import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Link, useLocation } from "react-router-dom";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import { toast } from 'react-toastify';
import { loginRequest } from "../authConfig";
import logo from '../Img/logoDigitaalOnthaal.png';
import { useHistory } from "react-router-dom";

function GuestHeaderContent() {
    const { instance } = useMsal();
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();
    const location = useLocation();
    const [dataLoaded, setDataLoaded] = useState(false);

    const init = useCallback(async () => {
        let primaryColor = "#d3950b"
        let secondaryColor = "#000000"

        try {
            let config = await service.getConfiguration();
            if (config.color !== "" && config.color !== undefined) {
                primaryColor = config.color;
            }
            else {
                if (process.env.REACT_APP_DEFAULT_PRIMARY_COLOR)
                    primaryColor = process.env.REACT_APP_DEFAULT_PRIMARY_COLOR;
            }
            document.documentElement.style.setProperty('--bs-custom-primary', primaryColor);
            document.documentElement.style.setProperty('--bs-custom-primary-text', textColor(primaryColor));

            if (config.secondaryColor !== "" && config.secondaryColor !== undefined) {
                secondaryColor = config.secondaryColor;
            }
            else {
                if (process.env.REACT_APP_DEFAULT_SECONDARY_COLOR)
                    secondaryColor = process.env.REACT_APP_DEFAULT_SECONDARY_COLOR;
            }
            document.documentElement.style.setProperty('--bs-custom-secondary', secondaryColor);
        }
        catch (error) {
            document.documentElement.style.setProperty('--bs-custom-primary', primaryColor);
            document.documentElement.style.setProperty('--bs-custom-primary-text', textColor(primaryColor));
            document.documentElement.style.setProperty('--bs-custom-secondary', secondaryColor);
        }
        setDataLoaded(true);
    }, [service]);

    useEffect(() => {
        if (!dataLoaded) {
            init();
        }
    }, [init, dataLoaded]);

    function textColor(c: any) {
        var color = c.substring(1); // strip #
        var rgb = parseInt(color, 16); // convert rrggbb to decimal
        var r = (rgb >> 16) & 0xff; // extract red
        var g = (rgb >> 8) & 0xff; // extract green
        var b = (rgb >> 0) & 0xff; // extract blue
        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        if (luma < 220) {
            return "#ffffff"
        }
        else {
            return "#666666"
        }
    }

    return (
        <header className="d-flex flex-wrap py-3 mb-4">
            <Link to="/choice" className="d-flex align-items-start mb-3 mb-md-0 me-md-auto text-decoration-none">
                <img src={logo} alt="Digitaal Onthaal" className="digitaal-onthaal-logo"></img>
            </Link>
            {location.pathname !== "/choice" && <i className="d-flex align-items-end bi bi-arrow-left-circle h1 custom-primary-color" onClick={() => history.push('/choice')}></i>}
        </header>
    );
}

function Header() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    const LoginHandler = () => {
        try {
            const authRequest = {
                ...loginRequest
            };

            instance.loginRedirect(authRequest);
        } catch (err) {
            toast.error("Inloggen mislukt.");
        }
    }

    const LogoutHandler = () => {
        try {
            instance.logout();
        } catch (err) {
            toast.error("Uitloggen mislukt.");
        }
    }

    const HeaderContent = () => {
        return (
            <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none ">
                    <img src={logo} alt="Digitaal Onthaal" className="digitaal-onthaal-logo"></img>
                </Link>
                <ul className="nav nav-pills align-items-end">
                    <li className="nav-item">
                        <i className="bi bi-door-closed fs-3" onClick={isAuthenticated ? LogoutHandler : LoginHandler}></i>
                    </li>
                </ul>
            </header>
        );
    };

    function isGuestLocation() {
        if (location.pathname === "/choice")
            return true;
        else if (location.pathname === "/guest")
            return true;
        else if (location.pathname === "/logoutGuest")
            return true;
        else
            return false;
    }

    return (
        <div className="container">
            {isGuestLocation() ? <GuestHeaderContent /> : <HeaderContent />}
        </div>
    );
}

export default Header;