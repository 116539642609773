import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
    ReactPlugin
} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPLICATIONINSIGHTS_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});
ai.loadAppInsights();

export { reactPlugin };

export function getError(error: unknown): Error {
    if (error instanceof Error) return error;
    return Error(String(error));
}